import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import HomePageWrapper from "./style"
import Intro from "./Intro"
import ContactPanel from "./Contact"
import TwoColumn from "./TwoColumn"
import FullWidth from "./FullWidthPanel"

type HomePageProps = {}

const HomePage: React.FunctionComponent<HomePageProps> = ({ ...props }) => {
  const Data = useStaticQuery(graphql`
    query {
      leafImage: file(absolutePath: { regex: "/suffolk-therapy-yellow-knot.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      meditationImage: file( absolutePath: { regex: "/suffolk-therapy-good-enough.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      satOnRocksImage: file(absolutePath: { regex: "/suffolk-therapy-writing.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wanderingImage: file(absolutePath: { regex: "/suffolk-therapy-leaves.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <HomePageWrapper {...props}>
      <Intro />

      <FullWidth
        title="Do you sometimes feel your words are just not enough?"
        content="Oftentimes the language of the spoken word is not enough to express the depth of our emotions. Writing, sketching, colour and journaling are a form of expression that can take you beyond words to aid restoration of your mind and body. Embarking on a therapeutic journey that takes you beyond just words in reflection can help you express your true Self more completely."
        image={Data.wanderingImage.childImageSharp.fluid}
        switch={true}
      />

      <TwoColumn
        title=""
        content="We do the best we can with what we have in the situation we find ourselves in. We can so easily fall back into our default position in life. No matter how hard you  try to make changes, when life becomes difficult do you struggle to stick with your good intentions? Talking therapy can help you ‘anchor’ yourself in the present without falling into past habits to empower you to make lasting changes that you are looking for."
        image={Data.leafImage.childImageSharp.fluid}
        switch={false}
      />
      <TwoColumn
        title=""
        content="Are you defined by what people think and say about you? Do those words weigh heavily on you?. Words can cause feelings of being devalued and worthless. Anxiousness and sadness can overwhelm causing us to become isolated. Different models of therapy can help to re-evaluate thought processes to help you see yourself in the true light of who you are and not how others see you."
        image={Data.meditationImage.childImageSharp.fluid}
        switch={true}
      />
      <TwoColumn
        title=""
        content="Do you feel like you are always adding new tasks to your to-do list but never crossing anything out? I can help you to achieve more of your personal and professional goals. Get in touch today and start taking control of your life with my coaching tools and techniques.This is some content."
        image={Data.satOnRocksImage.childImageSharp.fluid}
        switch={false}
      />

      <ContactPanel />
      
    </HomePageWrapper>
  )
}

export default HomePage
