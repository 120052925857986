import React from "react"
import Img from "gatsby-image"

import { TwoColumnWrapper, TwoColumnImage, TwoColumnInfo } from "./style"

type TwoColumnProps = {
  image: object;
  title: string;
  content: string;
  switch: boolean;
}

const TwoColumn: React.FunctionComponent<TwoColumnProps> = props => {
  return (
    <TwoColumnWrapper>
      {props.switch === true ? null : (
        <TwoColumnImage>
          <Img fluid={props.image} />
        </TwoColumnImage>
      )}

      <TwoColumnInfo>
        <h3>{props.title}</h3>
        <p>{props.content}</p>
      </TwoColumnInfo>

      {props.switch === false ? null : (
        <TwoColumnImage>
          <Img fluid={props.image} />
        </TwoColumnImage>
      )}
    </TwoColumnWrapper>
  )
}

export default TwoColumn
