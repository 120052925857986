import styled from "styled-components"
import { themeGet } from "styled-system"
import WheatFieldBG from "../../../images/suffolk-therapy-fields.jpg"

export const IntroWrapper = styled.div`
margin: 0 auto;
padding: 20px;
border-radius: 3px;

@media (min-width: 990px) {
  padding: 0 40px
}
@media (min-width: 1200px) {
  padding: 0 80px
}

`

export const IntroImage = styled.div`
  margin: 0 auto;
  padding: 30px;
  background-image: url(${WheatFieldBG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 3px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (min-width: 990px) {
    min-height: 500px
  }
  @media (min-width: 1200px) {
    min-height: 800px;
  }
`

export const IntroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.6);
  width: 100%;
  height: 100%;
`

export const IntroTitle = styled.h1`
  font-size: 44px;
  font-weight: 400;
  font-family: ${themeGet("fontFamily.1", "'Raleway', sans-serif")};
  color: #fff;
  display: inline-block;
  text-align: center;
  @media (max-width: 990px) {
    font-size: 26px;
  }
  @media (max-width: 575px) {
    font-size: 22px;
  }

  &:after {
    content: '';
    height: 8px;
    width: 100%;
    display: block;
    margin: 18px 0;
    background: ${themeGet("colors.mainTheme")};
  }
`

export const Desciption = styled.p`
  color: #fff;
  font-size: 18px;
  line-height: ${themeGet("lineHeights.text", "2")};
  text-align: center;
`

export const Heading = styled.h2`
  color: #fff;
  font-size: 20px;
  line-height: ${themeGet("lineHeights.text", "2")};
  text-align: center;
`


export const IntroInfo = styled.div`
  z-index: 1;
  text-align: center;
  max-width: 900px;
`
