import React from "react"
import Img from "gatsby-image"

import { FullWidthPanel, FullWidthPanelWrapper, FullWidthPanelImage, FullWidthPanelInfo } from "./style"

type FullWidthPanelProps = {
  image: object
  title: string
  content: string
  switch: boolean
}

const FullWidth: React.FunctionComponent<FullWidthPanelProps> = props => {
  return (
    <FullWidthPanel>
      <FullWidthPanelWrapper>
        {props.switch === true ? null : (
          <FullWidthPanelImage>
            <Img fluid={props.image} />
          </FullWidthPanelImage>
        )}

        <FullWidthPanelInfo>
        { props.title &&
          <h3>{props.title}</h3>
        }

        { props.content &&
          <p>{props.content}</p>
        }
        
        </FullWidthPanelInfo>

        {props.switch === false ? null : (
          <FullWidthPanelImage>
            <Img fluid={props.image} />
          </FullWidthPanelImage>
        )}
      </FullWidthPanelWrapper>
    </FullWidthPanel>
  )
}

export default FullWidth  
