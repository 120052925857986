import styled from "styled-components"
import { themeGet } from "styled-system"


export const TwoColumnWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0;
  max-width: 1080px;

  @media (min-width: 990px) {
    flex-direction: row;
    padding: 80px 40px 0;
  }
  @media (min-width: 1200px) {

  }
`

export const TwoColumnImage = styled.div`
  width: 100%;
  order: 1;

  @media (min-width: 990px) {
    width: 50%;
    order: initial;
  }

`

export const TwoColumnInfo = styled.div`
  color: #000;
  font-size: ${themeGet("fontSizes.4", "16")}px;
  line-height: ${themeGet("lineHeights.text", "2")};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 20px;

  p {
    margin: 0;
  }

  @media (min-width: 990px) {
    width: 50%;
    padding: 40px;
  }
  @media (min-width: 1200px) {
  
  }
`
