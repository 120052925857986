import styled from "styled-components"
import { themeGet } from "styled-system"
import LeafBG from "../../../images/suffolk-therapy-flower.jpg"

export const ContactWrapper = styled.div`
  
  margin: 0 auto;
  padding: 20px;
  display: flex;
  
  @media (min-width: 990px) {
    padding: 40px
  }
  @media (min-width: 1200px) {
    padding: 80px
  }
`


export const ContactPanelWrapper = styled.div`
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  max-width: 1080px;

  @media (min-width: 990px) {
    flex-direction: row;
    padding: 100px 40px;
    margin: 20px auto 0;
  }
  @media (min-width: 1200px) {
    
  }
`

export const ContactPanelImage = styled.div`
  width: 100%;
  display: none;
  position: relative;
  order: 1;
  background-image: url(${LeafBG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 990px) {
    display: block;
    width: 50%;
    order: initial;


  }

  &:after {
    contet: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.2);
  }

`

export const ContactPanelInfo = styled.div`
  background: rgb(227, 242, 243);
  color: #000;
  font-size: ${themeGet("fontSizes.4", "16")}px;
  line-height: ${themeGet("lineHeights.text", "2")};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 20px;

  p {
    margin: 0;
  }

  @media (min-width: 990px) {
    width: 50%;
    padding: 80px;
  }
  @media (min-width: 1200px) {
  
  }
`
