import React from "react"
import Contact from "../../Contact"

import { ContactWrapper, ContactPanelImage, ContactPanelInfo } from "./style"

type ContactProps = {}

const ContactPanel: React.FunctionComponent<ContactProps> = () => {

  return (
    <ContactWrapper>

        <ContactPanelInfo>
          <Contact />
        </ContactPanelInfo>

          <ContactPanelImage />
            
     
    </ContactWrapper>
  )
}

export default ContactPanel
