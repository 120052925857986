import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  IntroWrapper,
  IntroImage,
  IntroOverlay,
  IntroTitle,
  Desciption,
  Heading,
  IntroInfo,
} from "./style"

type IntroProps = {}

const Intro: React.FunctionComponent<IntroProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `)
  return (
    <IntroWrapper>
      <IntroImage>
        <IntroOverlay />
        <IntroInfo>
          <IntroTitle>
           Suffolk Counselling & Therapy
          </IntroTitle>
          <Heading>Based near Bury St Edmunds, Suffolk, Annie provides counselling and therapy in a relaxed enviornment for people from all walks of life.</Heading>
          <Desciption>Life throws many surprises at us, and while we cannot predict the outcome of any situation, we can take control of how we respond to certain situations.</Desciption>
        </IntroInfo>
      </IntroImage>
    </IntroWrapper>
  )
}

export default Intro
